// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function obfuscate(arg: unknown): any {
  if (arg === null || arg instanceof Date || typeof arg !== 'object') {
    return arg;
  }

  if (Array.isArray(arg)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return arg.map((item) => obfuscate(item));
  }

  return obfuscateObject(arg as Record<string, unknown>);
}

export function obfuscateObject(obj: Record<string, unknown>): Record<string, unknown> {
  const res: Record<string, unknown> = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    let obfuscatedValue: unknown;
    if (value !== null && typeof value === 'object') {
      obfuscatedValue = obfuscate(value);
    } else {
      obfuscatedValue = obfuscateValue(String(key), obj[key]);
    }
    res[key] = obfuscatedValue;
  });

  return res;
}

function obfuscateValue(key: string, value: unknown) {
  if (typeof value !== 'string') {
    // No sensitive info here
    return value;
  }

  if (key.match(/auth/i) || key.match(/token/i)) {
    if (value.match(/^bearer/i)) {
      return ellipsize(value, 11);
    }
    return ellipsize(value, 4);
  } else if (key.match(/pass/i)) {
    return '...';
  }

  return value;
}

export function ellipsize(str: string, maxLength: number): string {
  if (str.length <= maxLength) {
    return str;
  }
  return `${str.substring(0, maxLength)}...`;
}
